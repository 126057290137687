import React from 'react'

import LanguageSwitcher from '../language-switcher/language-switcher.component'
import Card from '../card/card.component'
import { ReactComponent as EjoinLogo } from '../../images/ejoin-logo.svg'

import { useTranslation, withTranslation } from 'react-i18next'

import {
  DividerContainer,
  LLogoContainer,
  CardsContainer,
  BackgroundFigure,
} from './divider.styles'

const Divider = () => {
  const { t } = useTranslation('home-page')

  return (
    <DividerContainer id="divider">
      <header>
        <div />
        <LLogoContainer>
          <EjoinLogo />
        </LLogoContainer>
        <LanguageSwitcher />
      </header>
      <CardsContainer>
        <Card
          href="https://product.ejoin.eu/"
          image={{
            src: '/card/1-v2.png',
            alt: 'Nabíjacie stanice',
            loading: 'lazy',
          }}
          title={t('c1')}
          description={t('c1d')}
          buttonLabel={t('button')}
        />
        <Card
          href="https://go.ejoin.eu/"
          image={{
            src: '/card/2-v2.png',
            alt: 'Sieť nabíjacích staníc',
            loading: 'lazy',
          }}
          title={t('c2')}
          description={t('c2d')}
          buttonLabel={t('button')}
        />
        <Card
          href={t('buttonLink')}
          image={{
            src: '/card/3-v2.png',
            alt: 'Developeri a bytové domy',
            loading: 'lazy',
          }}
          title={t('c3')}
          description={t('c3d')}
          buttonLabel={t('button')}
        />
      </CardsContainer>
      <BackgroundFigure>
        <img src="/background.svg" alt="divider" />
      </BackgroundFigure>
    </DividerContainer>
  )
}

export default withTranslation('home-page')(Divider)
