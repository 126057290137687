import React from 'react'
import styled from 'styled-components'

const Card = (props) => {
  const { image, title, description, href } = props

  return (
    <Container className="card" href={href}>
      <figure>
        <img src={image.src} alt={image.alt} {...image} />
      </figure>
      <p>{title}</p>
      <Description>{description}</Description>
    </Container>
  )
}

export default Card

const Description = styled.div`
  font-size: 1.3rem;
  color: #fff;
  font-weight: 300;
  text-align: center;
  max-width: 25rem;
  margin: 0 auto;
`

const Container = styled.a`
  display: block;
  max-width: 34rem;
  width: 100%;
  white-space: pre-line;
  transition: transform 0.3s ease-in-out;

  figure {
    position: relative;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 26.3rem;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  p {
    margin-top: 2.8rem;
    margin-bottom: 2rem;
    font-size: 2.7rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    color: #bfd600;
  }

  &:hover {
    transform: scale(1.05);
  }
`
