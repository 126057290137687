import { createGlobalStyle, css } from 'styled-components'

export const theme = {
  primary: '#BFD600',
  primaryLight: 'hsl(65.88785046728972, 100%, 51.96078431372549%)',
  backgroundPrimary: '#141313',
  backgroundSecondary: '#424242',
  backgroundMapContainer: '#111111',
  fontPrimary: '#FFFFFF',
  fontSecondary: '#FFFFFF',
  arrowColor: '#4E4E4E',
  selectButton: '#F8F8F8',
  selectButtonFont: '#fff',
  white: '#fff',
  black: '#000',
  red: '#ED4C67',
  ctaButtonColor: '#fff',
  ctaButtonBackground: '#BFD600',
  navItemLink: '#000',

  productItemBackgroundColor: '#F8F8F8',

  configuratorTitleBackgroundColor: '#BFD600',
  configuratorTitleColor: '#fff',
  configuratorSummaryBackgroundColor: '#F6F6F6',
  configuratorSummaryColor: '#000',

  'productSection-sm': '40.063em',
  'productSection-m': '64.063em',

  'big-screen': '90.063em',

  sm: '40.063em',
  m: '64.063em',
  l: '90.063em',
}

export const CustomScrollbarStyles = css`
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundPrimary};
    border-radius: 999rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.primary};
    border-radius: 999rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.primary};
  }
`

export const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    scroll-snap-type: y proximity;
    font-size: 62.5%;
    width: 100%;
    height:100%;
  }

  body {
    width: 100%;
    height:100%;
    line-height: 1.5;
    font-size: 1.6rem;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.fontPrimary};
    font-family: 'Raleway', sans-serif;
    line-height: 1.5;
    ${CustomScrollbarStyles} {
        ::-webkit-scrollbar {
          display: initial;
      }
    };

  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary};

  }

  ul, ol {
    list-style: none;
  }

  input, button {
    border: none;
    outline: none;
    font-size: inherit;
    font-family: inherit;

    &:focus {
      outline: none;
    }
  }

  section {
    /* width: 100%; */
  }
`

export const PrimaryButton = css`
  cursor: pointer;
  display: block;
  font-weight: bolder;
  background-color: ${({ theme }) => theme.ctaButtonBackground};
  color: ${({ theme }) => theme.ctaButtonColor};
  max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : '25rem')};
  width: 100%;
  font-size: 1.8rem;
  padding: 1.5rem;
  border-radius: ${({ pill }) => (pill ? '9999rem' : '1.3rem')};
  margin: ${({ center }) => center && '0 auto'};

  @media all and (max-width: 500px) {
    max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : '20rem')};
    font-size: 1.6rem;
  }
`

export const WhiteButton = css`
  cursor: pointer;
  display: block;
  font-weight: bolder;
  background-color: #fff;
  color: #000;
  max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : '25rem')};
  width: 100%;
  font-size: 1.8rem;
  padding: 1.5rem;
  border-radius: ${({ pill }) => (pill ? '9999rem' : '1.3rem')};
  margin: ${({ center }) => center && '0 auto'};
  box-shadow: 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.16);

  @media all and (max-width: 500px) {
    max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : '20rem')};
    font-size: 1.6rem;
  }
`

export const gap = css`
  --gap: 1rem;
  display: inline-flex;
  /* flex-wrap: wrap; */
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  & > * {
    margin: var(--gap) 0 0 var(--gap);
  }
`

export const gap2 = css`
  --gap: 2rem;
  display: inline-flex;
  /* flex-wrap: wrap; */
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  & > * {
    margin: var(--gap) 0 0 var(--gap);
  }
`

export const sectionPadding = css`
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 3rem;
  padding-right: 3rem;
  /* overflow: hidden; */
`
