import styled from 'styled-components'

export const LLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  svg {
    width: 10rem;
    height: 10rem;
  }
`

export const DividerContainer = styled.div`
  max-width: 136rem;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;

  header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin-top: 2.5rem;
    margin-bottom: 4.5rem;
  }
`

export const CardsContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  row-gap: 7.4rem;
  width: 100%;
`

export const BackgroundFigure = styled.figure`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  z-index: -1;
`
