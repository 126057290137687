import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ThemeProvider } from "styled-components"
import { GlobalStyles, theme } from "./styles/global.styles"

import "./i18n"

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<div />}>
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				<App />
			</ThemeProvider>
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
