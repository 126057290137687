import styled from 'styled-components'

export const Container = styled.div`
  z-index: 99;
  /* background-color: red; */
  margin-left: 1rem;
  display: flex;
  justify-self: end;

  button {
    background-color: transparent;
    border: none;
    margin: 0.5rem;
  }
`

export const Item = styled.p`
  width: 3rem;
  height: 3rem;
  display: block;
  cursor: pointer;
  /* background-color: ${({ theme, isActive }) =>
    isActive ? theme.primary : 'unset'}; */
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
`
