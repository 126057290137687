import React from "react"
import { useTranslation } from "react-i18next"

import { SK, GB } from "country-flag-icons/react/3x2"

import { Container, Item } from "./language-switcher.styles"

const LanguageSwitcher = () => {
	// const router = useRouter()
	const { i18n } = useTranslation()

	// console.log(router)
	const handleChangeLanguage = (lng) => {
		i18n.changeLanguage(lng)
	}

	// console.log(i18n.language)

	return (
		<Container>
			<button onClick={() => handleChangeLanguage("en")} locale="en">
				<Item isActive={i18n.language === "en"}>
					<GB title="Great Britain" />
				</Item>
			</button>
			<button onClick={() => handleChangeLanguage("sk")} locale="sk">
				<Item isActive={i18n.language === "sk"}>
					<SK />
				</Item>
			</button>
		</Container>
	)
}

export default LanguageSwitcher
